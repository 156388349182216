<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">a) Consider the reaction shown below:</p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$\ce{S(s) + O2(g) -> SO2(g),} \Delta\text{H}=-296\,\text{kJ,}$" />
      </p>

      <p class="mb-n3">
        Based on this information, which of the following statements is <b>FALSE</b>?
      </p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2 mt-2">b) I think this because:</p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'CalPolyPracticeExam3Q8',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {
          text: 'The reaction is exothermic.',
          value: 'a',
        },
        {
          text: `When 1 mol sulfur is reacted, $296\\,\\text{kJ}$ of energy is released to the surroundings.`,
          value: 'b',
        },
        {
          text: 'This is a combustion reaction',
          value: 'c',
        },
        {
          text: `The standard enthalpy of formation for $\\ce{SO_{2}(g)}$ is $–296\\,\\text{kJ}$`,
          value: 'd',
        },
        {
          text: 'Heat is absorbed during the reaction',
          value: 'e',
        },
      ],
    };
  },
};
</script>
